<template>
	<div class="min-height-screen h-full">
		<!-- password update error modal -->
		<modal v-model="hasPasswordUpdatingError">
			<template #body>
				<div class="d-flex flex-column px-2 pb-5 mx-auto">
					<h6 class="text-center text-h6 font-weight-bold mb-4">
						{{ passwordUpdateErrorMessage }}
					</h6>

					<div class="d-flex align-center justify-center">
						<v-btn
							large
							rounded
							type="button"
							color="primary"
							class="font-weight-bold"
							@click="
								hasPasswordUpdatingError = false
								passwordUpdateErrorMessage = null
							"
							>Ok</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<!-- password updated modal -->
		<modal v-model="isPasswordUpdated">
			<template #body>
				<div class="d-flex flex-column px-5 pb-5 mx-auto">
					<h6 class="text--secondary text-center text-h6 font-weight-bold mb-4">
						Your password updated successfully!!
					</h6>

					<div class="d-flex align-center justify-center">
						<v-btn large rounded type="button" color="primary" @click="isPasswordUpdated = false"
							>Ok</v-btn
						>
					</div>
				</div>
			</template>
		</modal>

		<v-card flat elevation="0" class="">
			<v-toolbar flat class="border-b-2 border--secondary mb-8 px-0 mb-8 py-3 !h-85px">
				<v-toolbar-title>
					<div class="d-flex align-center">
						<a :href="`${baseUrl}/me?token=${authToken}`" class="text-decoration-none mr-2">
							<v-icon color="primary">mdi-arrow-left</v-icon>
						</a>
						<strong> Change Password </strong>
					</div>
				</v-toolbar-title>
			</v-toolbar>

			<div class="px-3 h-full">
				<v-form ref="changePasswordFormRef" @submit.prevent="handleChangePassword" class="h-full">
					<v-row class="flex-column">
						<v-col cols="12" class="py-0">
							<label
								for="old-password"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Old Password</label
							>
							<div class="position-relative">
								<v-text-field
									id="old-password"
									outlined
									:rules="[requiredRule]"
									:type="isOldPasswordVisible ? 'text' : 'password'"
									v-model="oldPassword"
									class="rounded-small"
									placeholder="Enter Old Password"
								>
									<template v-slot:append>
										<button
											type="button"
											class="vis--btn"
											@click="isOldPasswordVisible = !isOldPasswordVisible"
										>
											<eye-icon v-if="isOldPasswordVisible" />
											<eye-hide-icon v-else />
										</button>
									</template>
								</v-text-field>
							</div>
						</v-col>
						<v-col cols="12" class="py-0">
							<label
								for="new-password"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>New Password</label
							>
							<div class="">
								<v-text-field
									id="new-password"
									outlined
									:type="isNewPasswordVisible ? 'text' : 'password'"
									v-model="newPassword"
									:rules="[requiredRule, sameAsPasswordRule]"
									class="rounded-small"
									placeholder="Enter New Password"
								>
									<template v-slot:append>
										<button
											type="button"
											class="vis--btn"
											@click="isNewPasswordVisible = !isNewPasswordVisible"
										>
											<eye-icon v-if="isNewPasswordVisible" />
											<eye-hide-icon v-else />
										</button>
									</template>
								</v-text-field>
							</div>
						</v-col>
						<v-col cols="12" class="py-0">
							<label
								for="confirm-new-password"
								class="modalLabel mb-2 d-inline-block text--secondary font-weight-bold"
								>Confirm New Password</label
							>
							<v-text-field
								id="confirm-new-password"
								outlined
								:rules="[requiredRule]"
								v-model="confirmNewPassword"
								:type="isConfirmNewPasswordVisible ? 'text' : 'password'"
								class="rounded-small"
								placeholder="Enter Confirm Password"
							>
								<template v-slot:append>
									<button
										class="vis--btn"
										type="button"
										@click="isConfirmNewPasswordVisible = !isConfirmNewPasswordVisible"
									>
										<eye-icon v-if="isConfirmNewPasswordVisible" />
										<eye-hide-icon v-else />
									</button>
								</template>
							</v-text-field>
						</v-col>
						<v-col cols="12" class="mt-14">
							<v-btn
								type="submit"
								color="primary"
								class="pa-3 h-auto w-full text-capitalize d-block rounded-small"
								:disabled="isSubmitting"
							>
								<strong class="text-capitalize text-h6 font-weight-bold">Save Password</strong>
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</div>
		</v-card>
	</div>
</template>

<script>
import AuthMixin from '@/mixins/auth'
import EyeIcon from '@/components/icons/EyeIcon.vue'
import EyeHideIcon from '@/components/icons/EyeHideIcon.vue'
import { changeUserPassword } from '@/services/profile'
import { requiredRule } from '@/validations'
import { SUCCESS, ERROR } from '@/constants/status-code'

const Modal = () => import('@/components/Modal.vue')

export default {
	name: 'ChangePassword',
	mixins: [AuthMixin],
	components: {
		Modal,
		EyeIcon,
		EyeHideIcon,
	},
	data() {
		return {
			requiredRule,
			isSubmitting: false,
			isOldPasswordVisible: false,
			isNewPasswordVisible: false,
			isConfirmNewPasswordVisible: false,
			isPasswordUpdated: false,
			hasPasswordUpdatingError: false,
			passwordUpdateErrorMessage: null,
			oldPassword: '',
			newPassword: '',
			confirmNewPassword: '',
		}
	},
	mounted() {
		// show password success modal
		if (typeof this.$route.query.password === 'string' && this.$route.query.password) {
			this.isPasswordUpdated = true
		}
	},
	watch: {
		confirmNewPassword() {
			this.$refs.changePasswordFormRef.validate()
		},
	},
	methods: {
		sameAsPasswordRule(v) {
			if (this.confirmNewPassword) {
				if (v !== this.confirmNewPassword) return 'Password does not match'
			}
			return true
		},
		async handleChangePassword() {
			if (!this.$refs.changePasswordFormRef.validate()) return
			this.isSubmitting = true

			const formData = {
				oldPassword: this.oldPassword,
				password: this.newPassword,
				confirmPassword: this.confirmNewPassword,
			}
			// eslint-disable-next-line prefer-destructuring
			const newPassword = this.newPassword

			this.$refs.changePasswordFormRef.reset()

			try {
				const res = await changeUserPassword(this.authToken, { formData })
				const { statusCode, message } = res.data
				if (statusCode === SUCCESS) {
					this.isPasswordUpdated = true
					window.location.href = `/me/password/change?token=${this.authToken}&password=${newPassword}`
				}
				if (statusCode === ERROR) {
					this.hasPasswordUpdatingError = true
					this.passwordUpdateErrorMessage = message
				}
			} catch (e) {
				this.hasPasswordUpdatingError = true
				this.passwordUpdateErrorMessage = 'Oops!! something went wrong'
			}
			this.isSubmitting = false
		},
	},
}
</script>

<style lang="scss" scoped>
.vis--btn {
	width: 2.2rem;
	height: 1.6rem;
}
</style>
